import { useEffect } from "react";

export const AdminMenu2 = ({
  children,
  menu2Active,
  setMenu2Active,
  menu,
  setMenuLength,
  setEditMode,
  setFilePreview,
  setCreateFundHer
}) => {
  useEffect(() => {
    setMenuLength(menu.length);
  }, [setMenuLength, menu.length]);

  function handleClick(id) {
    setMenu2Active(id);
    setEditMode(false);
    setFilePreview(false);
    setCreateFundHer(false);
  }

  return (
    <>
      <div className="admin-menu2">
        <ul>
          {menu.map((item, index) => (
            <AdminMenu2Item
              key={index}
              icon={item.icon}
              title={item.title}
              menu2Active={menu2Active}
              id={item.id}
              onHandleClick={handleClick}
            />
          ))}
        </ul>
      </div>
      {children}
    </>
  );
};

const AdminMenu2Item = ({ icon, title, id, menu2Active, onHandleClick }) => {
  return (
    <li
      style={
        menu2Active === id
          ? { color: "#C30532", borderBottom: "2px solid #C30532" }
          : { color: "#474747", borderBottom: "2px solid #474747" }
      }
      onClick={() => onHandleClick(id)}
      className={!icon && "noIcon"}
    >
      {icon}
      <h4
        style={menu2Active === id ? { color: "#C30532" } : { color: "#474747" }}
      >
        {title}
      </h4>
    </li>
  );
};

export default AdminMenu2;
