import React, { useState, useEffect } from "react";
import axios from "axios";
import FormSuccessfulMessage from "./FormSuccessfulMessage";
import DatePicker from "./DatePicker1";
import Timepicker from "./Timepicker1";

const generateRandomId = () => {
  return Math.floor(Math.random() * 100) + 1;
};

const AdminProgrammeForm = ({
  setMenu2Active,
  itemToEdit,
  editMode,
  isLoading,
  setIsLoading,
}) => {
  const [programmeFormData, setProgrammeFormData] = useState({
    title: "",
    description: "",
    feature: [""],
    currency: "",
    date: "",
    time: "",
    duration: "",
    amount: "",
    picture: null,
    field: [""],
    cta: null,
  });
  const [isToggle, setIsToggle] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [imageToggle, setImageToggle] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (editMode) {
      setProgrammeFormData(itemToEdit);
      setImageToggle(true);
    }
  }, [editMode, itemToEdit]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setProgrammeFormData({
      ...programmeFormData,
      [name]: value,
    });
  };

  const getImage = (e) => {
    const fileData = e.target.files[0];

    setProgrammeFormData({
      ...programmeFormData,
      picture: fileData,
    });
  };

  const handleFeatureChange = (index, value) => {
    const newFeatures = [...programmeFormData.feature];
    const randomId = generateRandomId();
    newFeatures[index] = {
      id: randomId,
      feature: value,
    };
    setProgrammeFormData((prevState) => ({
      ...prevState,
      feature: newFeatures,
    }));
  };

  const addFeature = () => {
    setProgrammeFormData({
      ...programmeFormData,
      feature: [
        ...programmeFormData.feature,
        {
          feature: "",
        },
      ],
    });
  };

  const deleteDynamicInput = (indexToRemove, arrayName) => {
    setProgrammeFormData((prevState) => ({
      ...prevState,
      [arrayName]: prevState[arrayName].filter(
        (_, index) => index !== indexToRemove
      ),
    }));
  };

  function handleQuestionChange(index, value) {
    let newQuestions;

    newQuestions = [...programmeFormData.field];
    newQuestions[index] = value;
    setProgrammeFormData((prevState) => ({
      ...prevState,
      field: newQuestions,
    }));
  }

  const addQuestion = () => {
    setProgrammeFormData((prevState) => ({
      ...prevState,
      field: [...prevState.field, ""],
    }));
  };

  const postProgram = async (formData) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://9jadelivery.com/femaletechMobile/public/api/createPrograms",
        formData
      );
      if (response.data && response.data.code === 200) {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error posting program:", error.message);
      throw error;
    }
  };

  const editProgram = async (formData) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `https://9jadelivery.com/femaletechMobile/public/api/editPrograms`,
        formData
      );
      if (response.data && response.data.code === 200) {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error posting program:", error.message);
      throw error;
    }
  };

  const addProgram = async (payload) => {
    const formData = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
      if (key === "feature") {
        value.forEach((feature, index) => {
          Object.entries(feature).forEach(([featureKey, featureValue]) => {
            formData.append(`feature[${index}][${featureKey}]`, featureValue);
          });
        });
      } else if (key === "field") {
        value.forEach((field, index) => {
          formData.append(`field[${index}]`, field);
        });
      } else {
        formData.append(key, value);
      }
    });

    try {
      editMode ? await editProgram(formData) : await postProgram(formData);
      setFormSubmitted(true);
    } catch (err) {
      setErrorMessage(err.message);
      setIsLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isAnyFieldEmpty = Object.entries(programmeFormData).some(
      ([key, value]) => {
        if (Array.isArray(value)) {
          return value.some((item) => item === "");
        }
        if (key === "picture" || key === "cta") {
          return value === null;
        }
        return value === "";
      }
    );

    if (isAnyFieldEmpty && !editMode) {
      setInputError(true);
      return;
    }

    addProgram(programmeFormData);

    setInputError(false);
  };

  const handleBackBtn = () => {
    setIsToggle(!isToggle);
    setInputError(false);
  };

  const handleFormHomeBtn = () => {
    setMenu2Active(1);
  };

  return (
    <div className="programme-form">
      {errorMessage ? (
        <ErrorMessage message={`Error submitting form : ${errorMessage}`} />
      ) : (
        <form onSubmit={isLoading ? (e) => e.preventDefault() : handleSubmit}>
          {!isToggle && !formSubmitted && (
            <div>
              <label>
                Title:
                <input
                  type="text"
                  name="title"
                  value={programmeFormData.title}
                  onChange={handleInputChange}
                  placeholder="Nill"
                />
              </label>
              <br />
              <label>
                Description:
                <textarea
                  id="description"
                  name="description"
                  rows="4"
                  cols="50"
                  value={programmeFormData.description}
                  onChange={handleInputChange}
                  placeholder="Enter a description"
                ></textarea>
              </label>
              <br />
              <label>
                Date:
                <DatePicker
                  selectedDate={programmeFormData.date}
                  onDateChange={(date) =>
                    handleInputChange({
                      target: { name: "date", value: date },
                    })
                  }
                />
              </label>
              <br />
              <label>
                Time:
                <Timepicker
                  selectedTime={programmeFormData.time}
                  onTimeChange={(time) =>
                    handleInputChange({
                      target: { name: "time", value: time },
                    })
                  }
                />
              </label>
              <br />
              <label>
                List features:
                {programmeFormData.feature.map((feature, index) => (
                  <div key={index} className="input-options">
                    <input
                      type="text"
                      value={feature.feature}
                      onChange={(e) =>
                        handleFeatureChange(index, e.target.value)
                      }
                      placeholder={`Feature ${index + 1}`}
                      className="dynamic-input"
                    />
                    <button
                      type="button"
                      className="delete-input"
                      onClick={() => deleteDynamicInput(index, "feature")}
                    >
                      x
                    </button>
                  </div>
                ))}
                <button type="button" onClick={addFeature}>
                  + Add another
                </button>
              </label>
              <br />
              <label>
                Currency:
                <input
                  type="text"
                  name="currency"
                  value={programmeFormData.currency}
                  onChange={handleInputChange}
                  placeholder="Nill"
                />
              </label>
              <br />
              <label>
                Amount:
                <input
                  type="number"
                  name="amount"
                  value={programmeFormData.amount}
                  onChange={handleInputChange}
                  placeholder="0"
                />
              </label>
              <br />

              <button
                type="button"
                className="continue btn2Style"
                onClick={() => setIsToggle(!isToggle)}
              >
                Continue
              </button>
              <br />
            </div>
          )}
          {isToggle && !formSubmitted && (
            <div>
              {editMode && imageToggle && (
                <div>
                  <img
                    className="form-flyer"
                    src={programmeFormData.picture}
                    alt="Programme Flyer"
                  />
                  <p
                    className="remove-form-flyer"
                    onClick={() => setImageToggle(false)}
                  >
                    Remove Image
                  </p>
                </div>
              )}
              {!imageToggle && (
                <label>
                  Upload picture:
                  <input
                    type="file"
                    id="image"
                    accept=".png, .jpg, .jpeg"
                    onChange={(e) => getImage(e)}
                  />
                </label>
              )}
              <br />
              <label>
                Duration:
                <input
                  type="text"
                  name="duration"
                  value={programmeFormData.duration}
                  onChange={handleInputChange}
                  placeholder="Nill"
                />
              </label>
              <h3>Add input field(s) for mobile registration</h3>
              <br />
              <label>
                Input question:
                {programmeFormData.field.map((field, index) => (
                  <div key={index} className="input-options">
                    <input
                      type="text"
                      value={field}
                      onChange={(e) =>
                        handleQuestionChange(index, e.target.value)
                      }
                      placeholder={`Question ${index + 1}`}
                      className="dynamic-input"
                    />
                    <button
                      type="button"
                      className="delete-input"
                      onClick={() => deleteDynamicInput(index, "field")}
                    >
                      x
                    </button>
                  </div>
                ))}
                <button type="button" onClick={addQuestion}>
                  + Add another question
                </button>
              </label>
              <br />
              <label>
                Call to action:
                <input
                  type="text"
                  name="cta"
                  value={programmeFormData.cta}
                  onChange={handleInputChange}
                  placeholder="Nill"
                />
              </label>
              <br />
              <button
                type="button"
                style={{ marginTop: "1rem" }}
                className="continue btn2Style"
                onClick={handleBackBtn}
              >
                Back
              </button>
              <button
                type="submit"
                style={{ cursor: isLoading ? "not-allowed" : "pointer" }}
                className="continue btn2Style"
              >
                {editMode ? "Update program" : "Submit"}
              </button>
              {inputError && (
                <p className="input-error">⛔ Please fill in all fields</p>
              )}
            </div>
          )}
          {formSubmitted && isToggle && (
            <FormSuccessfulMessage
              text={
                editMode
                  ? "Programme updated successfully"
                  : "Programme added successfully"
              }
              onclick={handleFormHomeBtn}
            />
          )}
        </form>
      )}
    </div>
  );
};

const ErrorMessage = ({ message }) => {
  return (
    <p className="error">
      <span>⛔️</span> {message}
    </p>
  );
};

export default AdminProgrammeForm;
