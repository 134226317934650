import React from "react";
import { Navigate } from "react-router-dom";

// HOC that checks if a user is authenticated
const withAuth = (WrappedComponent) => {
  return (props) => {
    const isAuthenticated = !!localStorage.getItem("id");

    if (!isAuthenticated) {
      return <Navigate to="/" />;
    }

    return <WrappedComponent {...props} />;
  };
};

export default withAuth;
