import React, { useState, useEffect } from "react";
import axios from "axios";
import { ErrorMessage, Loader } from "./AdminStructure1";
import ResourceCover from "../assets/images/resource.png";
import Lock from "../assets/images/lock.png";
import DeleteConfirmation from "./DeleteConfirmation.js";

export const AdminResources = ({
  setItemToEdit,
  setEditMode,
  setMenu2Active,
  menuLength,
  setArticleLink,
  setFilePreview,
  setcoverImagePreview,
  confirmBox2,
  setConfirmBox2,
  eventID,
  isActive,
  menu2Active,
  setEventID,
}) => {
  const [allResources, setAllResources] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchResources = async () => {
      try {
        setIsLoading(true);
        const response = await axios.post(
          "https://9jadelivery.com/femaletechMobile/public/api/getResources",
          { id: "14" }
        );
        setAllResources(response.data.AllResources);
        setIsLoading(false);
      } catch (error) {
        setErrorMessage(error.message);
        setIsLoading(false);
      }
    };

    fetchResources();
  }, [setIsLoading, setErrorMessage]);

  const handleResourceEdit = (id, api) => {
    const fetch = async () => {
      try {
        const response = await axios.get(api, { params: { id: id } });
        if (response.data) {
          if (response.data.article_link.includes("article_file")) {
            setArticleLink(response.data.article_link);
            setMenu2Active(menuLength);
          }
          setItemToEdit(response.data);
          setEditMode(true);
          setFilePreview(true);
          setcoverImagePreview(true);
          setMenu2Active(menuLength);
        }
      } catch (error) {
        console.error("Error fetching program:", error);
      }
    };

    fetch();
  };
  return (
    <>
      {errorMessage && (
        <ErrorMessage
          message={`Error fetching program data: ${errorMessage}`}
        />
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <ul className="resource-page">
          {allResources &&
            allResources.length > 0 &&
            allResources.map((item, index) => (
              <Resource
                resource={item}
                key={index}
                handleResourceEdit={handleResourceEdit}
                confirmBox2={confirmBox2}
                setConfirmBox2={setConfirmBox2}
                setEventID={setEventID}
                eventID={eventID}
                setEditMode={setEditMode}
                isActive={isActive}
                menu2Active={menu2Active}
                setMenu2Active={setMenu2Active}
                menuLength={menuLength}
              />
            ))}
        </ul>
      )}
    </>
  );
};

const Resource = ({
  resource,
  handleResourceEdit,
  confirmBox2,
  setConfirmBox2,
  setEventID,
  eventID,
  setEditMode,
  isActive,
  menu2Active,
  menuLength,
  setMenu2Active,
}) => {
  const [expandedResourceId, setExpandedResourceId] = useState(null);
  const fullDescription = resource.description;
  const truncatedDescription = resource.description
    .split(" ")
    .slice(0, 13)
    .join(" ");

  const toggleDescription = (id) => {
    setExpandedResourceId(expandedResourceId === id ? null : id);
  };

  let cover;

  if (resource.typeOfContent === "audio") {
    cover = (
      <>
        <audio controls>
          <source
            src={`https://9jadelivery.com/femaletechMobile/storage/app/${resource.audio}`}
            type="audio/mp3"
          />
          Your browser does not support the audio element. Upload mp3
        </audio>
        <img
          src={
            resource.cover_image
              ? `https://9jadelivery.com/femaletechMobile/storage/app/${resource.cover_image}`
              : ResourceCover
          }
          alt="ResourceCover"
        />
      </>
    );
  } else if (resource.typeOfContent === "video") {
    cover = (
      <video controls width="250" height="150">
        <source
          src={`https://9jadelivery.com/femaletechMobile/storage/app/${resource.video}`}
          type="video/mp4"
        />
        Your browser does not support the video element. Upload mp4
      </video>
    );
  } else if (resource.typeOfContent === "external_link") {
    cover = (
      <a href={resource.external_link} target="_blank" rel="noreferrer">
        <img
          src={
            resource.cover_image
              ? `https://9jadelivery.com/femaletechMobile/storage/app/${resource.cover_image}`
              : ResourceCover
          }
          alt="ResourceCover"
        />
      </a>
    );
  } else {
    cover = (
      <img
        src={
          resource.cover_image
            ? `https://9jadelivery.com/femaletechMobile/storage/app/${resource.cover_image}`
            : ResourceCover
        }
        alt="ResourceCover"
      />
    );
  }
  return (
    <>
      <li>
        <div>
          {resource.payment_type === "Paid" && (
            <div className="lock">
              <img src={Lock} alt="Lock" />
            </div>
          )}

          <p
            className={
              resource.payment_type === "Paid"
                ? "resource-type"
                : "resource-type free-resource"
            }
            style={
              resource.typeOfContent === "writeUp"
                ? resource.payment_type === "Paid"
                  ? { marginLeft: "8rem" }
                  : { marginLeft: "10.5rem" }
                : null
            }
          >
            {`Resource type: ${
              resource.typeOfContent === "external_link"
                ? "link"
                : resource.typeOfContent
            }`}
          </p>
        </div>
        <div>{cover}</div>
        <div className="resource-content">
          {resource.typeOfContent === "external_link" ? (
            <a href={resource.external_link} target="_blank" rel="noreferrer">
              <h3>{resource.title}</h3>
            </a>
          ) : (
            <h3>{resource.title}</h3>
          )}

          <p>
            {expandedResourceId === resource.id
              ? fullDescription
              : truncatedDescription}
            {fullDescription.split(" ").length > 13 && (
              <span onClick={() => toggleDescription(resource.id)}>
                {expandedResourceId === resource.id ? ` see less` : ` see all`}
              </span>
            )}
          </p>
          <div>
            <button>
              <svg
                width="13"
                height="12"
                viewBox="0 0 15 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() =>
                  handleResourceEdit(
                    resource.id,
                    "https://9jadelivery.com/femaletechMobile/public/api/getResource"
                  )
                }
              >
                <path
                  d="M12.5041 4.73699L9.24384 1.51507L10.3178 0.441096C10.6119 0.147032 10.9732 0 11.4018 0C11.8298 0 12.1909 0.147032 12.4849 0.441096L13.5589 1.51507C13.853 1.80913 14.0064 2.16405 14.0192 2.57984C14.032 2.99511 13.8913 3.34977 13.5973 3.64384L12.5041 4.73699ZM11.3918 5.86849L3.26027 14H0V10.7397L8.13151 2.60822L11.3918 5.86849Z"
                  fill="#C3053C"
                />
              </svg>
            </button>
            <button>
              <svg
                width="10"
                height="12"
                viewBox="0 0 12 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => {
                  setEventID(resource.id);
                  setConfirmBox2(true);
                }}
              >
                <path
                  d="M5.14502 2.625H6.89502C6.89502 2.39294 6.80283 2.17038 6.63874 2.00628C6.47464 1.84219 6.25208 1.75 6.02002 1.75C5.78796 1.75 5.5654 1.84219 5.4013 2.00628C5.23721 2.17038 5.14502 2.39294 5.14502 2.625ZM4.27002 2.625C4.27002 2.16087 4.45439 1.71575 4.78258 1.38756C5.11077 1.05937 5.55589 0.875 6.02002 0.875C6.48415 0.875 6.92927 1.05937 7.25746 1.38756C7.58564 1.71575 7.77002 2.16087 7.77002 2.625H11.27C11.3861 2.625 11.4973 2.67109 11.5794 2.75314C11.6614 2.83519 11.7075 2.94647 11.7075 3.0625C11.7075 3.17853 11.6614 3.28981 11.5794 3.37186C11.4973 3.45391 11.3861 3.5 11.27 3.5H10.7765L9.72214 11.2333C9.65063 11.7573 9.39166 12.2377 8.99318 12.5855C8.5947 12.9333 8.08369 13.125 7.55477 13.125H4.48527C3.95635 13.125 3.44534 12.9333 3.04686 12.5855C2.64837 12.2377 2.38941 11.7573 2.31789 11.2333L1.26352 3.5H0.77002C0.653987 3.5 0.542707 3.45391 0.46066 3.37186C0.378613 3.28981 0.33252 3.17853 0.33252 3.0625C0.33252 2.94647 0.378613 2.83519 0.46066 2.75314C0.542707 2.67109 0.653987 2.625 0.77002 2.625H4.27002ZM5.14502 5.6875C5.14502 5.57147 5.09893 5.46019 5.01688 5.37814C4.93483 5.29609 4.82355 5.25 4.70752 5.25C4.59149 5.25 4.48021 5.29609 4.39816 5.37814C4.31611 5.46019 4.27002 5.57147 4.27002 5.6875V10.0625C4.27002 10.1785 4.31611 10.2898 4.39816 10.3719C4.48021 10.4539 4.59149 10.5 4.70752 10.5C4.82355 10.5 4.93483 10.4539 5.01688 10.3719C5.09893 10.2898 5.14502 10.1785 5.14502 10.0625V5.6875ZM7.33252 5.25C7.21649 5.25 7.10521 5.29609 7.02316 5.37814C6.94111 5.46019 6.89502 5.57147 6.89502 5.6875V10.0625C6.89502 10.1785 6.94111 10.2898 7.02316 10.3719C7.10521 10.4539 7.21649 10.5 7.33252 10.5C7.44855 10.5 7.55983 10.4539 7.64188 10.3719C7.72393 10.2898 7.77002 10.1785 7.77002 10.0625V5.6875C7.77002 5.57147 7.72393 5.46019 7.64188 5.37814C7.55983 5.29609 7.44855 5.25 7.33252 5.25Z"
                  fill="#C3053C"
                />
              </svg>
            </button>
          </div>
        </div>
      </li>
      {confirmBox2 && (
        <DeleteConfirmation
          setConfirmBox2={setConfirmBox2}
          eventID={eventID}
          api={
            "https://9jadelivery.com/femaletechMobile/public/api/deleteResources"
          }
          setEditMode={setEditMode}
          isActive={isActive}
          menu2Active={menu2Active}
          setMenu2Active={setMenu2Active}
          menuLength={menuLength}
        />
      )}
    </>
  );
};

export default AdminResources;
