import React, { useState } from "react";
import axios from "axios";
import { ErrorMessage, Loader } from "./AdminStructure1";
import ClubPlaceholder from "../assets/images/resource.png";

export const ClubRequests = ({
  allMentorInfo,
  setAllMentorInfo,
  apiData,
  isLoading,
  setIsLoading,
  errorMessage,
  confirmBox,
  setConfirmBox,
}) => {
  const [selectedClubId, setSelectedClubId] = useState(null);



  return (
    <>
      {errorMessage && (
        <ErrorMessage
          message={`Error fetching program data: ${errorMessage}`}
        />
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <ul className="mentorship-page resource-page">
          {apiData &&
            apiData.length > 0 &&
            apiData.map((item, index) => (
              <ClubRequest
                clubRequest={item}
                key={index}
                allMentorInfo={allMentorInfo}
                setAllMentorInfo={setAllMentorInfo}
                selectedClubId={selectedClubId}
                setSelectedClubId={setSelectedClubId}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                confirmBox={confirmBox}
                setConfirmBox={setConfirmBox}
              />
            ))}
        </ul>
      )}
    </>
  );
};

const ClubRequest = ({
  clubRequest,
  allMentorInfo,
  setAllMentorInfo,
  selectedClubId,
  setSelectedClubId,
  onClubRequests,
  isLoading,
  confirmBox,
  setIsLoading,
  setConfirmBox,
}) => {
  const handleLearnMoreClick = () => {
    setSelectedClubId(clubRequest.id);
    setAllMentorInfo(true);
  };

  return (
    <>
      <li
        style={{
          width: "35rem",
          height: "15rem",
          cursor: "pointer",
        }}
        onClick={handleLearnMoreClick}
      >
        <div style={{ width: "52%", position: "static", padding: "0" }}>
          <img
            src={
              clubRequest.picture.includes("images")
                ? clubRequest.picture
                : ClubPlaceholder
            }
            style={{ width: "100%", height: "137px", objectFit: "cover", borderRadius: "5px" }}
            alt="Applicant Cover"
            className="mindfulness-cover"
          />
        </div>
        <div
          style={{
            width: "80%",
          }}
        >
          <h3
            style={{
              fontSize: "1.35rem",
              lineHeight: "1.3em",
              fontWeight: "600",
              marginBottom: "0.5rem",
            }}
          >
            {clubRequest.clubName}
          </h3>
          <p
            style={{
              fontSize: "1.1rem",
              lineHeight: "1.25em",
              color: "#212529",
            }}
          >
            {clubRequest.clubDescription}
          </p>

          <div
            style={{
              marginTop: "1.5rem",
            }}
          >
            <h5
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                marginBottom: "0.8rem",
                color: "#C3053C",
              }}
            >
              Admin
            </h5>
            <div
              style={{ display: "flex", alignItems: "center", gap: "0.8rem" }}
            >
              <div style={{ width: "15%" }}>
                <img
                  src={
                    clubRequest.adminPicture.includes("images")
                      ? clubRequest.adminPicture
                      : ClubPlaceholder
                  }
                  style={{ borderRadius: "100px" }}
                  alt="Applicant Cover"
                  className="mindfulness-cover"
                />
              </div>
              <div>
                <h4
                  style={{
                    fontSize: "1.15rem",
                    lineHeight: "1.3em",
                    fontWeight: "600",
                  }}
                >
                  {clubRequest.adminName}
                </h4>
                <p
                  style={{
                    fontSize: "0.9rem",
                    color: "#828282",
                    lineHeight: "1.25em",
                  }}
                >
                  {clubRequest.adminEmail}
                </p>
              </div>
            </div>
          </div>
        </div>
      </li>

      {allMentorInfo && selectedClubId === clubRequest.id && (
        <ClubInformation
          clubRequest={clubRequest}
          setAllMentorInfo={setAllMentorInfo}
          onClubRequests={onClubRequests}
          isLoading={isLoading}
          setSelectedClubId={setSelectedClubId}
          setConfirmBox={setConfirmBox}
        />
      )}
        {confirmBox && selectedClubId === clubRequest.id && (
        <AcceptConfirmation
          clubRequest={clubRequest}
          setConfirmBox={setConfirmBox}
          setAllMentorInfo={setAllMentorInfo}
          onClubRequests={onClubRequests}
          isLoading = {isLoading}
          setIsLoading={setIsLoading}
          setSelectedClubId={setSelectedClubId}
        />
      )}
              {confirmBox && selectedClubId === clubRequest.id && (
        <RejectConfirmation
          clubRequest={clubRequest}
          setConfirmBox={setConfirmBox}
          setAllMentorInfo={setAllMentorInfo}
          onClubRequests={onClubRequests}
          isLoading = {isLoading}
          setIsLoading={setIsLoading}
          setSelectedClubId={setSelectedClubId}
        />
      )}
    </>
  );
};

const ClubInformation = ({
  clubRequest,
  setAllMentorInfo,
  onClubRequests,
  isLoading,
  setConfirmBox,
  setSelectedClubId
}) => {

  const handleClubRequestAccept = () => {
    setAllMentorInfo(false);
    setConfirmBox(true);
  };

  const handleClubRequestReject = () => {
    setAllMentorInfo(false);
    setConfirmBox(true);
  };


  return (
    <div
      style={{
        position: "absolute",
        width: "36rem",
        height: "40.5rem",
        borderRadius: "12px",
        background: "#ffffff",
        padding: "1.3rem 2rem 1.8rem 2rem",
        overflow: "auto",
        fontSize: "1.3rem",
        left: "53rem",
        zIndex: "999",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "1rem",
        }}
      >
        <button
          style={{
            padding: "0.7rem 1rem",
            fontSize: "1.4rem",
            background: "none",
          }}
          onClick={() => setAllMentorInfo(false)}
        >
          X
        </button>
      </div>
      <div
        style={{
          marginBottom: "2.2rem",
        }}
      >
        <div style={{ width: "25%", position: "static", padding: "0" }}>
          <img
            src={
              clubRequest.picture.includes("images")
                ? clubRequest.picture
                : ClubPlaceholder
            }
            style={{
              width: "100%",
              borderRadius: "5px",
              height: "80px",
              objectFit: "cover",
            }}
            alt="Applicant Cover"
            className="mindfulness-cover"
          />
        </div>
        <h3
          style={{
            fontSize: "1.35rem",
            lineHeight: "1.3em",
            fontWeight: "600",
            marginTop: "1.2rem",
          }}
        >
          {clubRequest.clubName}
        </h3>
      </div>
      <div>
        <h4
          style={{
            fontSize: "1.2rem",
            lineHeight: "1.3em",
            fontWeight: "600",
            marginBottom: "0.5rem",
          }}
        >
          About
        </h4>
        <p
          style={{
            fontSize: "1.1rem",
            lineHeight: "1.25em",
            color: "#212529",
          }}
        >
          {clubRequest.clubDescription}
        </p>
      </div>
      <div
        style={{
          marginTop: "1.8rem",
        }}
      >
        <h5
          style={{
            fontSize: "1rem",
            fontWeight: "600",
            marginBottom: "0.8rem",
            color: "#C3053C",
          }}
        >
          Admin
        </h5>
        <div style={{ display: "flex", alignItems: "center", gap: "0.8rem" }}>
          <div style={{ width: "10%" }}>
            <img
              src={
                clubRequest.adminPicture.includes("images")
                  ? clubRequest.adminPicture
                  : ClubPlaceholder
              }
              style={{ borderRadius: "100px" }}
              alt="Applicant Cover"
              className="mindfulness-cover"
            />
          </div>
          <div>
            <h4
              style={{
                fontSize: "1.15rem",
                lineHeight: "1.3em",
                fontWeight: "600",
              }}
            >
              {clubRequest.adminName}
            </h4>
            <p
              style={{
                fontSize: "0.9rem",
                color: "#828282",
                lineHeight: "1.25em",
              }}
            >
              {clubRequest.adminEmail}
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: "3.6rem",
        }}
      >
        <button
          style={{
            padding: "0.7rem 1rem",
            marginLeft: "0",
            marginRight: "2rem",
            width: "46%",
            fontWeight: "400",
            cursor: isLoading ? "not-allowed" : "pointer",
            textTransform: "none",
            fontSize: "1.3rem",
          }}
          className="btn2Style"
          onClick={handleClubRequestAccept}
        >
          Accept
        </button>
        <button
          style={{
            padding: "0.7rem 1rem",
            width: "46%",
            fontWeight: "400",
            cursor: isLoading ? "not-allowed" : "pointer",
            textTransform: "none",
            fontSize: "1.3rem",
          }}
          className="btn1Style"
          onClick={handleClubRequestReject}
        >
          Reject
        </button>
      </div>
    </div>
  );
};

const AcceptConfirmation = ({
  clubRequest,
  setAllMentorInfo,
  setConfirmBox,
  isLoading,
  setIsLoading
}) => {

  const handleAccept = async (api, email, clubId) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://9jadelivery.com/femaletechMobile/public/api/adminAllowedClub",
        {
          "email":clubRequest.adminEmail,
          "canAllowClub":true,
          "clubId":clubRequest.id
          }
      );
      
      console.log(response.data);
      if (response.status === 200) {
        setIsLoading(false);
        setAllMentorInfo(false);
        setConfirmBox(false);
      } else {
        console.log("Failed to accept club request: ", response.data);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error.message);
    }
  };

  const handleConfirmationCancel = () => {
    setAllMentorInfo(true);
    setConfirmBox(false);
  };
  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "43rem",
        height: "20rem",
        borderRadius: "12px",
        background: "#ffffff",
        overflow: "auto",
        fontSize: "1.3rem",
        left: "53rem",
        pointerEvents: "all",
        zIndex: 999,
        gap:"2rem",
        paddingTop:"1.2rem"
      }}
    >
      <p
        style={{
          fontSize: "3rem",
          textAlign: "center",
        }}
      >
        Are you sure you want to proceed?
      </p>
      <div
        style={{
          margin: "2.6rem 0",
          display: "flex",
          gap: "2rem",
          justifyContent: "space-between",
        }}
      >
        <button
          style={{
            width: "46%",
            marginLeft: "2rem",
            fontWeight: "700",
            background: "none",
            color: "#c3053c",
            textTransform: "none",
            fontSize: "1.2rem",
          }}
          onClick={handleConfirmationCancel}
        >
          Cancel
        </button>

        <button
          style={{
            marginLeft: "0",
            marginRight: "2rem",
            width: "46%",
            fontWeight: "700",
            textTransform: "none",
            fontSize: "1.2rem",
             cursor: isLoading ? "not-allowed" : "pointer"
          }}
          className="btn2Style"
          onClick={handleAccept}
        >
          Proceed
        </button>
      </div>
    </div>
  );
};

const RejectConfirmation = ({
  clubRequest,
  setAllMentorInfo,
  setConfirmBox,
  isLoading,
  setIsLoading
}) => {

  const handleReject = async (api, email, clubId) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://9jadelivery.com/femaletechMobile/public/api/adminDeclinedClub",
        {
          "email":clubRequest.adminEmail,
          "canAllowClub":true,
          "clubId":clubRequest.id
          }
      );
      
      console.log(response.data);
      if (response.status === 200) {
        setIsLoading(false);
        setAllMentorInfo(false);
        setConfirmBox(false);
      } else {
        console.log("Failed to reject club request: ", response.data);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error.message);
    }
  };

  const handleConfirmationCancel = () => {
    setAllMentorInfo(true);
    setConfirmBox(false);
  };
  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "43rem",
        height: "20rem",
        borderRadius: "12px",
        background: "#ffffff",
        overflow: "auto",
        fontSize: "1.3rem",
        left: "53rem",
        pointerEvents: "all",
        zIndex: 999,
        gap:"2rem",
        paddingTop:"1.2rem"
      }}
    >
      <p
        style={{
          fontSize: "3rem",
          textAlign: "center",
        }}
      >
        Are you sure you want to proceed?
      </p>
      <div
        style={{
          margin: "2.6rem 0",
          display: "flex",
          gap: "2rem",
          justifyContent: "space-between",
        }}
      >
        <button
          style={{
            width: "46%",
            marginLeft: "2rem",
            fontWeight: "700",
            background: "none",
            color: "#c3053c",
            textTransform: "none",
            fontSize: "1.2rem",
          }}
          onClick={handleConfirmationCancel}
        >
          Cancel
        </button>

        <button
          style={{
            marginLeft: "0",
            marginRight: "2rem",
            width: "46%",
            fontWeight: "700",
            textTransform: "none",
            fontSize: "1.2rem",
             cursor: isLoading ? "not-allowed" : "pointer"
          }}
          className="btn2Style"
          onClick={handleReject}
        >
          Proceed
        </button>
      </div>
    </div>
  );
};


export default ClubRequests;
