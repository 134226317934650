import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const DatePicker1 = ({ selectedDate = null, onDateChange }) => {
  const [isDatepickerOpen, setDatepickerOpen] = useState(false);

  const handleDateChange = (date) => {
    if (date) {
      onDateChange(moment(date).format("YYYY-MM-DD"));
      setDatepickerOpen(false);
    } else {
      onDateChange(null);
    }
  };

  return (
    <div className="calendar">
      <DatePicker
        selected={selectedDate ? new Date(selectedDate) : null}
        onChange={handleDateChange}
        dateFormat="yyyy-MM-dd"
        placeholderText="Select a date"
        open={isDatepickerOpen}
        onFocus={() => setDatepickerOpen(!isDatepickerOpen)}
        onBlur={() => setDatepickerOpen(false)}
      />
    </div>
  );
};

export default DatePicker1;
