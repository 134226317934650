import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const generateColor = (value, index) => {
  if (index === 0) return "hsl(200, 100%, 70%)"; // Light blue for the first segment
  if (index === 1) return "hsl(0, 0%, 100%)"; // / White for the second segment

  // For other segments, generate colors based on index or value
  const hue = (index * 40) % 360; // Rotate hue for variety
  return `hsl(${hue}, 60%, 70%)`; // Soft colors for additional segments
};

const PieChart = ({ locations, values }) => {
  const backgroundColors = values.map((value, index) =>
    generateColor(value, index)
  );

  const data = {
    labels: locations,
    datasets: [
      {
        data: values,
        backgroundColor: backgroundColors,
        hoverBackgroundColor: backgroundColors,
        borderColor: "#c30532",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false, // Hides the legend
      },
    },
  };

  return <Pie data={data} options={options} />;
};

export default PieChart;
