import React, { useState } from "react";
import axios from "axios";
import SearchIcon from "../assets/icons/search.svg";

const SearchComponent = ({ allUsers, setAllUsers, isActive, fetchMembers }) => {
  const [searchQuery, setSearchQuery] = useState("");


  const fetchUsers = async () => {
    try {
      const response = await axios.post(
        "https://9jadelivery.com/femaletechMobile/public/api/user",
        { module: "Admin" }
      );
      if (response.data) {
        setAllUsers(response.data);
      }
    } catch (error) {
      // Handle error
    }
  };

  const handleUsersSearchInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    filterData(searchQuery);
  };

  const handleBackspacePress = (event) => {
    if (event.keyCode === 8 || event.key === "Backspace") {
      if (isActive === 7) {
        fetchUsers();
      } else {
        fetchMembers();
      }
    }
  };

  const filterData = (query) => {
    const filtered = allUsers?.filter((item) => {
      if (item.fullName && typeof item.fullName === "string") {
        return item.fullName.toLowerCase().includes(query.toLowerCase());
      }
      return false;
    });
    setAllUsers(filtered);
  };

  return (
    <div className="search">
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleUsersSearchInputChange}
        onKeyDown={handleBackspacePress}
      />
      <img src={SearchIcon} alt="Search Icon" />
    </div>
  );
};

export default SearchComponent;
