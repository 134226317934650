import NotificationIcon from "../assets/icons/notification.svg";
import SearchIcon from "../assets/icons/search.svg";
import User from "../assets/images/user.png";

const AdminNavbar = () => {

  return (
    <header>
      <div>
        <Greetings user="Admin" />
      </div>
      <div>
        <Search />
        <Notification />
        <Greetings user="BBTech">
          <img src={User} alt="User" />
        </Greetings>
      </div>
    </header>
  );
};

const Greetings = ({ children, user }) => {
  return (
    <div className="greetings">
      {children}
      <p>Hi, {user}</p>
    </div>
  );
};

export const Search = () => {
  return (
    <div className="search">
      <input type="text" placeholder="Search..." />
      <img src={SearchIcon} alt="Search Icon" />
    </div>
  );
};

const Notification = () => {
  return (
    <div className="notification">
      <img src={NotificationIcon} alt="Notification Icon" />
    </div>
  );
};

export default AdminNavbar;
