import React, { useState, useEffect } from "react";
import axios from "axios";
import FormSuccessfulMessage from "./FormSuccessfulMessage";

const AdminSpotlightForm = ({
  setMenu2Active,
  itemToEdit,
  editMode,
  coverImagePreview,
  setcoverImagePreview,
  isLoading,
  setIsLoading,
}) => {
  const [spotlightFormData, setSpotlightFormData] = useState({
    content: null,
    url: "",
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (editMode) {
      setSpotlightFormData((prevFormData) => ({
        ...prevFormData,
        id: itemToEdit.id,
        url: itemToEdit.url,
        content: itemToEdit.content,
      }));
    }
  }, [editMode, itemToEdit, itemToEdit.typeOfContent]);

  const handleInputChange = ({ target }) => {
    const { name, value } = target;

    setSpotlightFormData({
      ...spotlightFormData,
      [name]: value,
    });
  };

  const handleSpolightUpload = (e) => {
    const fileData = e.target.files[0];

    setSpotlightFormData({
      ...spotlightFormData,
      content: fileData,
    });
  };

  const postSpotlight = async (resourceData) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://9jadelivery.com/femaletechMobile/public/api/createSpotlight",
        resourceData
      );
      if (response.data && response.data.code === 200) {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error posting program:", error.message);
      throw error;
    }
  };

  const editSpotlight = async (resourceData) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://9jadelivery.com/femaletechMobile/public/api/createSpotlight",
        resourceData
      );
      if (response.data && response.data.code === 200) {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error posting program:", error.message);
      throw error;
    }
  };

  const validateForm = (spotlightFormData) => {
    const isAnyFieldEmpty = Object.entries(spotlightFormData).some(
      ([key, value]) => {
        if (key === "content") {
          return value === null;
        }
        return value === "";
      }
    );

    if (isAnyFieldEmpty) {
      setInputError(true);
      return;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    validateForm(spotlightFormData);
    addSpotlight(spotlightFormData);

    setInputError(false);
  };

  const addSpotlight = async (payload) => {
    const formData = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
      formData.append(key, value);
    });

    try {
      editMode ? await editSpotlight(formData) : await postSpotlight(formData);
      setFormSubmitted(true);
    } catch (err) {
      setErrorMessage(err.message);
      setIsLoading(false);
    }
  };

  const handleFormHomeBtn = () => {
    setMenu2Active(1);
  };

  return (
    <div className="resource-form event-form programme-form">
     {errorMessage ? (
        <ErrorMessage
          message={`Error submitting form : ${errorMessage}`}
        />
      ) :( <form onSubmit={isLoading ? (e) => e.preventDefault() : handleSubmit}>
        {!formSubmitted ? (
          <div>
            {coverImagePreview ? (
              <div>
                <img
                  className="form-flyer"
                  src={spotlightFormData.content}
                  alt="Spotlight Cover"
                />
                <p
                  className="remove-form-flyer"
                  onClick={() => setcoverImagePreview(false)}
                >
                  Remove spotlight
                </p>
              </div>
            ) : (
              <label>
                Upload spotlight banner:
                <input
                  type="file"
                  id="image"
                  accept="image/*"
                  onChange={(e) => handleSpolightUpload(e)}
                  required
                />
              </label>
            )}
            <br />
            <label>
              Url:
              <input
                type="text"
                name="url"
                value={spotlightFormData.url}
                onChange={handleInputChange}
                placeholder="Nill"
              />
            </label>

            <br />
            <button type="submit" style={{ cursor: isLoading ? "not-allowed" : "pointer" }} className="continue btn2Style">
              {editMode ? "Update content" : "Upload content"}
            </button>
            {inputError && (
              <p className="input-error">⛔ Please fill in all fields</p>
            )}
          </div>
        ) : (
          <FormSuccessfulMessage
            text={
              editMode
                ? "Spotlight updated successfully"
                : "Spotlight uploaded successfully"
            }
            onclick={handleFormHomeBtn}
          />
        )}
      </form>)}
    </div>
  );
};

const ErrorMessage = ({ message }) => {
  return (
    <p className="error">
      <span>⛔️</span> {message}
    </p>
  );
}

export default AdminSpotlightForm;
