import React, { useState, useEffect } from "react";
import { Logo } from "./Navbar";
import axios from "axios";
import LoginImg from "../assets/images/login-img.png";
import Button from "./Button";
import Spinner from "./Spinner";
import { useNavigate } from "react-router-dom";

const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [adminEmailForResetPassword, setAdminEmailForResetPassword] =
    useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError("");
      }, 3000);
      return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }
  }, [error]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        "https://9jadelivery.com/femaletechMobile/public/api/adminLogin",
        {
          email,
          password,
        }
      );

      const { id } = response.data;

      if (response.status === 200 && response.data.id) {
        localStorage.setItem("id", JSON.stringify(id));
        navigate("/dashboard");
        setLoading(false);
      } else {
        setError(response.data.message || "Login failed. Please try again.");
        setLoading(false);
      }
    } catch (err) {
      setError("Server Request Error");
      setLoading(false);
    }
  };

  const handleAdminEmailConfirmation = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        "https://9jadelivery.com/femaletechMobile/public/api/adminRequestedForResetPassword",
        {
          email,
        }
      );

      if (response.status === 200 && response.data) {
        setAdminEmailForResetPassword(true);
        setLoading(false);
      }
    } catch (err) {
      setError("Server Request Error");
      setLoading(false);
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        "https://9jadelivery.com/femaletechMobile/public/api/adminConfirmToken",
        {
          email,
          token: "550280",
          newPassword,
        }
      );

      console.log(response.data);

      if (response.status === 200 && response.data) {
        setMessage("SUCCESSFUL!");
        setLoading(false);
        setTimeout(() => {
          setForgotPassword(false);
        }, 5000);
      }
    } catch (err) {
      setError("Server Request Error");
      setLoading(false);
    }
  };

  const handleForgotPasswordBtn = () => {
    setForgotPassword(true);
    setEmail("");
    setPassword("");
    setError("");
  };

  const handleBackBtn = () => {
    setAdminEmailForResetPassword(false);
    setForgotPassword(false);
  };

  return (
    <div
      style={{ display: "flex", height: "100vh" }}
      className="admin-login-page"
    >
      <Logo />
      <div>
        <img src={LoginImg} alt="LoginImg" />
      </div>
      {!forgotPassword ? (
        <div>
          <h2
            style={{
              marginBottom: "3rem",
              fontWeight: "800",
              fontSize: "2.2rem",
            }}
          >
            Sign-In
          </h2>
          <form onSubmit={handleLogin}>
            <div className="email">
              <label>Email address</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div>
              <label>Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "1rem",
              }}
            >
              <h5
                style={{
                  textDecoration: "underline",
                  fontWeight: "500",
                  cursor: "pointer",
                }}
                onClick={handleForgotPasswordBtn}
              >
                Forgot password
              </h5>
            </div>
            {error && <p style={{ marginTop: "1rem" }}>{error}</p>}
            <Button
              text={loading ? <Spinner /> : "Sign in"}
              className="btn2Style"
              style={{ textTransform: "none" }}
              disabled={loading}
            />
          </form>
        </div>
      ) : (
        <div>
          <h2
            style={{
              marginBottom: "5rem",
              fontWeight: "800",
              fontSize: "2.2rem",
            }}
          >
            Forgot Password
          </h2>
          <form>
            {!adminEmailForResetPassword ? (
              <>
                <div className="email">
                  <label>Email address</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                {error && <p style={{ marginTop: "1rem" }}>{error}</p>}
                <Button
                  text={loading ? <Spinner /> : "Next"}
                  className="btn2Style"
                  style={{ textTransform: "none" }}
                  disabled={loading}
                  onclick={handleAdminEmailConfirmation}
                />
                <Button
                  text={"⬅ Back"}
                  className="btn2Style"
                  style={{ textTransform: "none", marginTop: "2rem" }}
                  onclick={handleBackBtn}
                />
              </>
            ) : (
              <>
                <div>
                  <label>Enter new password</label>
                  <input
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />
                </div>
                {message && (
                  <p style={{ marginTop: "1rem", color: "green" }}>{message}</p>
                )}
                {error && <p style={{ marginTop: "1rem" }}>{error}</p>}
                <Button
                  text={loading ? <Spinner /> : "Submit"}
                  className="btn2Style"
                  style={{ textTransform: "none" }}
                  disabled={loading}
                  onclick={handleForgotPassword}
                />
                <Button
                  text={"⬅ Back"}
                  className="btn2Style"
                  style={{ textTransform: "none", marginTop: "2rem" }}
                  onclick={handleBackBtn}
                />
              </>
            )}
          </form>
        </div>
      )}
    </div>
  );
};

export default AdminLogin;
