import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Button from "./Button";
import Success from "../../src/assets/images/success.png";

const AddPastEventPicturesForm = ({
  setConfirmBox,
  setIsPastEventPictures,
  isLoading,
  setIsLoading,
  editMode,
  eventID,
  setMenu2Active,
  itemToEdit,
}) => {
  const [pastEventData, setPastEventData] = useState({
    id: eventID,
    pastEventPictures: [],
  });
  const [imagePreviews, setImagePreviews] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const fileInputRef = useRef(null);
  const [lastFileName, setLastFileName] = useState("No file chosen yet");

  useEffect(() => {
    if (editMode) {
      setPastEventData((prevFormData) => ({
        ...prevFormData,
        id: itemToEdit.id,
        pastEventPictures: itemToEdit.pastEventPictures,
      }));
      setImagePreviews(itemToEdit.pastEventPictures);
    }
  }, [editMode, itemToEdit]);

  const handleAddPicture = (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const fileArray = Array.from(files);

      setLastFileName(
        fileArray[fileArray.length - 1]?.name || "No file chosen yet"
      );

      const previewArray = fileArray?.map((file) => URL.createObjectURL(file));
      setImagePreviews((prevPreviews) => [...(prevPreviews || []), ...previewArray]);

      setPastEventData((prevState) => ({
        ...prevState,
        pastEventPictures: [
          ...(prevState.pastEventPictures || []),
          ...fileArray,
        ],
      }));
    } else {
      setLastFileName("No file chosen yet");
    }
  };

  const handleRemovePicture = (index) => {
    setPastEventData((prevState) => {
      const updatedPictures = prevState.pastEventPictures.filter(
        (_, i) => i !== index
      );

      if (updatedPictures.length === 0 && fileInputRef.current) {
        fileInputRef.current.value = null;
        setLastFileName("No file chosen");
      } else if (updatedPictures.length > 0) {
        setLastFileName(updatedPictures[updatedPictures.length - 1].name);
      }

      return {
        ...prevState,
        pastEventPictures: updatedPictures,
      };
    });

    setImagePreviews((prevPreviews) =>
      prevPreviews.filter((_, i) => i !== index)
    );
  };

  const addPastEvents = async (eventData) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://9jadelivery.com/femaletechMobile/public/api/markEventAsDone",
        eventData
      );
      if (response.status === 200) {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error posting program:", error.message);
      throw error;
    }
  };

  const editPastEvents = async (eventData) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `https://9jadelivery.com/femaletechMobile/public/api/markEventAsDone`,
        eventData
      );
      if (response.status === 200) {
        setIsLoading(false);
        setConfirmBox(false);
        setIsPastEventPictures(false);
        setMenu2Active(1);
      }
    } catch (error) {
      console.error("Error posting program:", error.message);
      throw error;
    }
  };

  const postPastEvents = async (payload) => {
    const formData = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
      if (key === "pastEventPictures") {
        payload.pastEventPictures.forEach((img, index) => {
          formData.append(`pastEventPictures[${index}]`, img);
        });
      } else {
        formData.append(key, value);
      }
    });

    try {
      editMode ? await editPastEvents(formData) : await addPastEvents(formData);
      setFormSubmitted(true);
    } catch (err) {
      setErrorMessage(err.message);
      setIsLoading(false);
    }
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleCloseBtn = (e) => {
    setConfirmBox(false);
    setIsPastEventPictures(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // console.log(pastEventData);

    postPastEvents(pastEventData);
  };

  const handleFormHomeBtn = () => {
    setConfirmBox(false);
    setIsPastEventPictures(false);
    setMenu2Active(1);
  };

  return (
    <div
      style={{
        position: "absolute",
        width: "43rem",
        height: "45rem",
        borderRadius: "12px",
        background: "#ffffff",
        overflow: "auto",
        left: "53rem",
        top: "15rem",
        pointerEvents: "all",
        zIndex: 999,
        padding: "1.5rem",
      }}
    >
      {" "}
      {errorMessage ? (
        <ErrorMessage message={`Error submitting form : ${errorMessage}`} />
      ) : (
        <form onSubmit={isLoading ? (e) => e.preventDefault() : handleSubmit}>
          {!formSubmitted ? (
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h3>Add event pictures</h3>
                <button
                  type="button"
                  style={{
                    background: "none",
                    border: "none",
                    fontWeight: "700",
                    fontSize: "1.5rem",
                  }}
                  onClick={handleCloseBtn}
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 6L18.7742 18.7742"
                      stroke="#14142B"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6 18.7734L18.7742 5.99924"
                      stroke="#14142B"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "14rem",
                  background: "#F2F2F2",
                  borderRadius: "8px",
                  margin: "6rem 0 2rem 0",
                  paddingLeft: "6rem",
                }}
              >
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={handleAddPicture}
                  ref={fileInputRef}
                  style={{
                    display: "none",
                  }}
                />
                <Button
                  type="button"
                  text="Browse"
                  style={{
                    width: "50%",
                    fontWeight: "800",
                    fontSize: "1.2rem",
                    textTransform: "none",
                    position: "relative",
                    right: "3.8rem",
                    color: "#fbfefb",
                  }}
                  className="btn2Style"
                  onclick={handleClick}
                />
              </div>
              <label htmlFor="fileInput">{lastFileName}</label>

              <div
                style={{
                  marginTop: "2rem",
                }}
              >
                {imagePreviews?.map((picture, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "3rem",
                    }}
                  >
                    <img
                      src={picture}
                      alt={`Preview ${index + 1}`}
                      style={{
                        width: "40px",
                        height: "40px",
                        marginRight: "10px",
                      }}
                    />
                    <button
                      type="button"
                      style={{
                        background: "none",
                        border: "none",
                      }}
                      onClick={() => handleRemovePicture(index)}
                    >
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.25 0.75L0.75 11.25M0.75 0.75L11.25 11.25"
                          stroke="#C30532"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                ))}
              </div>

              <br />
              <button
                type="submit"
                style={{
                  cursor: isLoading ? "not-allowed" : "pointer",
                  width: "100%",
                  marginLeft: "0",
                  textTransform: "none",
                  fontWeight: "700",
                  fontSize: "1.4rem",
                  color: "#FBFEFB",
                }}
                className="continue btn2Style"
              >
                {editMode ? "Update content" : "Save content"}
              </button>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className="success-img">
                <img src={Success} alt="Successful" style={{ margin: "0" }} />
              </div>
              <p style={{ margin: "6rem 0 3rem 0" }}>
                {editMode
                  ? "Event updated successfully"
                  : "Event listed successfully"}
              </p>
              <Button
                text="Home"
                style={{
                  width: "100%",
                  fontWeight: "800",
                  fontSize: "1.2rem",
                  textTransform: "none",
                }}
                className="btn2Style"
                onclick={handleFormHomeBtn}
              />
            </div>
          )}
        </form>
      )}
    </div>
  );
};

const ErrorMessage = ({ message }) => {
  return (
    <p className="error">
      <span>⛔️</span> {message}
    </p>
  );
};

export default AddPastEventPicturesForm;
