import { BrowserRouter, Routes, Route } from "react-router-dom";
import AdminDashboard from "./components/pages/AdminDashboard";
import AdminLogin from "./components/AdminLogin";
import Logout from "./components/Logout";
import "./styles/app.css";


const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<AdminLogin />} />
      <Route path="/dashboard" element={<AdminDashboard />} />
      <Route path="/logout" element={<Logout />} />
    </Routes>
  </BrowserRouter>
);

export default App;
