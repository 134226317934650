import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ErrorMessage, Loader } from "./AdminStructure1";
import ResourceCover from "../assets/images/resource.png";
import Lock from "../assets/images/lock.png";
import DeleteConfirmation from "./DeleteConfirmation.js";

export const AdminPodcast = ({
  onDelete,
  setItemToEdit,
  setEditMode,
  setMenu2Active,
  menuLength,
  setFilePreview,
  setcoverImagePreview,
  confirmBox2,
  setConfirmBox2,
  eventID,
  isActive,
  menu2Active,
  setEventID,
}) => {
  const [allPodcasts, setAllPodcasts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [expandedItemId, setExpandedItemId] = useState(null);

  useEffect(() => {
    const fetchPodcasts = async () => {
      try {
        setIsLoading(true);
        const response = await axios.post(
          "https://9jadelivery.com/femaletechMobile/public/api/getPodcast"
        );
        setAllPodcasts(response.data);
        setIsLoading(false);
      } catch (error) {
        setErrorMessage(error.message);
        setIsLoading(false);
      }
    };

    fetchPodcasts();
  }, [setIsLoading, setErrorMessage]);

  const handlePodcastEdit = (id) => {
    const fetch = async () => {
      try {
        const response = await axios.post(
          "https://9jadelivery.com/femaletechMobile/public/api/getPodcast",
          { id: id }
        );
        if (response.data) {
          console.log(response.data);
          setItemToEdit(response.data);
          setEditMode(true);
          setFilePreview(true);
          setcoverImagePreview(true);
          setMenu2Active(menuLength);
        }
      } catch (error) {
        console.error("Error fetching program:", error);
      }
    };

    fetch();
  };

  const toggleExpand = (id) => {
    setExpandedItemId((prevId) => (prevId === id ? null : id));
  };

  return (
    <>
      {errorMessage && (
        <ErrorMessage
          message={`Error fetching program data: ${errorMessage}`}
        />
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <ul className="podcast-page resource-page">
          {allPodcasts &&
            allPodcasts.length > 0 &&
            allPodcasts.map((item, index) => (
              <Podcast
                podcast={item}
                key={index}
                onDelete={onDelete}
                handlePodcastEdit={handlePodcastEdit}
                isExpanded={expandedItemId === item.id} // Check if this item is expanded
                toggleExpand={() => toggleExpand(item.id)} // Pass toggle function
                confirmBox2={confirmBox2}
                setConfirmBox2={setConfirmBox2}
                setEventID={setEventID}
                eventID={eventID}
                setEditMode={setEditMode}
                isActive={isActive}
                menu2Active={menu2Active}
                setMenu2Active={setMenu2Active}
                menuLength={menuLength}
              />
            ))}
        </ul>
      )}
    </>
  );
};

const Podcast = ({
  podcast,
  handlePodcastEdit,
  isExpanded,
  toggleExpand,
  confirmBox2,
  setConfirmBox2,
  setEventID,
  eventID,
  setEditMode,
  isActive,
  menu2Active,
  menuLength,
  setMenu2Active,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleAudioEnded = () => {
    setIsPlaying(false); // Reset play state when audio ends
  };
  return (
    <>
      <li>
        {podcast.payment_type === "Paid" && (
          <div className="lock">
            <img src={Lock} style={{ width: "30%" }} alt="Lock" />
          </div>
        )}
        <div style={{ position: "static", width: "38%" }}>
          <div>
            <img
              src={
                podcast.cover_image.includes("images")
                  ? podcast.cover_image
                  : ResourceCover
              }
              style={{ width: "100%" }}
              alt="Podcast Cover"
              className="mindfulness-cover"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "54%",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3
              style={{
                fontSize: "1.7rem",
                lineHeight: "1.3em",
                fontWeight: "600",
              }}
            >
              {podcast.title}
            </h3>
            <svg
              width="24"
              height="16"
              viewBox="0 0 4 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                cursor: "pointer",
              }}
              onClick={toggleExpand} // Call toggle function when clicked
            >
              <path
                d="M1.75 3.875C0.7835 3.875 0 3.0915 0 2.125C0 1.1585 0.7835 0.375 1.75 0.375C2.7165 0.375 3.5 1.1585 3.5 2.125C3.5 3.0915 2.7165 3.875 1.75 3.875ZM1.75 9.875C0.7835 9.875 0 9.0915 0 8.125C0 7.1585 0.7835 6.375 1.75 6.375C2.7165 6.375 3.5 7.1585 3.5 8.125C3.5 9.0915 2.7165 9.875 1.75 9.875ZM0 14.125C0 15.0915 0.7835 15.875 1.75 15.875C2.7165 15.875 3.5 15.0915 3.5 14.125C3.5 13.1585 2.7165 12.375 1.75 12.375C0.7835 12.375 0 13.1585 0 14.125Z"
                fill="#212121"
              />
            </svg>
            {isExpanded && (
              <div
                style={{
                  padding: "1.5rem",
                  position: "absolute",
                  background: "#FFFFFF",
                  margin: "3rem 0 0 4rem",
                  boxShadow: "2px 2px 8px #88888863",
                }}
              >
                <button
                  onClick={() =>
                    handlePodcastEdit(
                      podcast.id,
                      "https://9jadelivery.com/femaletechMobile/public/api/getPodcast"
                    )
                  }
                >
                  Edit
                </button>
                <button
                  onClick={() => {
                    setEventID(podcast.id);
                    setConfirmBox2(true);
                  }}
                >
                  Delete Podcast
                </button>
              </div>
            )}
          </div>
          <div style={{ display: "none" }}>
            <audio ref={audioRef} onEnded={handleAudioEnded}>
              <source src={podcast.audio} type="audio/mp3" />
              Your browser does not support the audio element. Upload mp3
            </audio>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {isPlaying ? (
              <svg
                width="30"
                height="31"
                viewBox="0 0 44 44"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={togglePlay}
              >
                <circle cx="22" cy="22" r="22" fill="#BAE6D4" />
                <path
                  d="M19 14H17C16.4696 14 15.9609 14.2239 15.5858 14.6224C15.2107 15.0209 15 15.5614 15 16.125V28.875C15 29.4386 15.2107 29.9791 15.5858 30.3776C15.9609 30.7761 16.4696 31 17 31H19C19.5304 31 20.0391 30.7761 20.4142 30.3776C20.7893 29.9791 21 29.4386 21 28.875V16.125C21 15.5614 20.7893 15.0209 20.4142 14.6224C20.0391 14.2239 19.5304 14 19 14Z"
                  fill="#C30532"
                />
                <path
                  d="M27 14H25C24.4696 14 23.9609 14.2239 23.5858 14.6224C23.2107 15.0209 23 15.5614 23 16.125V28.875C23 29.4386 23.2107 29.9791 23.5858 30.3776C23.9609 30.7761 24.4696 31 25 31H27C27.5304 31 28.0391 30.7761 28.4142 30.3776C28.7893 29.9791 29 29.4386 29 28.875V16.125C29 15.5614 28.7893 15.0209 28.4142 14.6224C28.0391 14.2239 27.5304 14 27 14Z"
                  fill="#C30532"
                />
              </svg>
            ) : (
              <svg
                width="30"
                height="31"
                viewBox="0 0 44 45"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={togglePlay}
              >
                <circle cx="22" cy="22" r="22" fill="#BAE6D4" />
                <path
                  d="M30.99 22.6462L17.5488 30.2604L17.6753 14.8129L30.99 22.6462Z"
                  fill="#C30532"
                />
              </svg>
            )}
            <svg
              width="28"
              height="28"
              viewBox="0 0 39 39"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M28.6212 17.7405C23.5045 14.7025 14.9501 14.3827 10.0733 15.9017C9.2738 16.1415 8.47432 15.6618 8.23447 14.9423C7.99463 14.1428 8.47432 13.3434 9.19385 13.1035C14.8701 11.4246 24.2241 11.7444 30.1402 15.2621C30.8597 15.6618 31.0996 16.6212 30.6998 17.3408C30.3001 17.9004 29.3407 18.1402 28.6212 17.7405ZM28.4613 22.2176C28.0616 22.7772 27.342 23.0171 26.7824 22.6173C22.4652 19.979 15.9095 19.1796 10.8728 20.7785C10.2332 20.9384 9.51364 20.6186 9.35374 19.979C9.19385 19.3395 9.51364 18.6199 10.1532 18.46C15.9894 16.7012 23.1847 17.5806 28.1415 20.6186C28.6212 20.8585 28.861 21.6579 28.4613 22.2176ZM26.5425 26.6147C26.2228 27.0944 25.6631 27.2543 25.1834 26.9345C21.4259 24.616 16.709 24.1363 11.1126 25.4155C10.553 25.5754 10.0733 25.1756 9.91338 24.696C9.75348 24.1363 10.1532 23.6566 10.6329 23.4967C16.709 22.1376 21.9855 22.6973 26.1428 25.2556C26.7024 25.4954 26.7824 26.135 26.5425 26.6147ZM19.1873 3.50977C17.0876 3.50977 15.0083 3.92335 13.0684 4.7269C11.1284 5.53045 9.36576 6.70824 7.88099 8.19301C4.88237 11.1916 3.19775 15.2586 3.19775 19.4993C3.19775 23.74 4.88237 27.8071 7.88099 30.8057C9.36576 32.2905 11.1284 33.4682 13.0684 34.2718C15.0083 35.0753 17.0876 35.4889 19.1873 35.4889C23.428 35.4889 27.4951 33.8043 30.4937 30.8057C33.4923 27.8071 35.1769 23.74 35.1769 19.4993C35.1769 17.3996 34.7633 15.3203 33.9598 13.3804C33.1562 11.4405 31.9784 9.67778 30.4937 8.19301C29.0089 6.70824 27.2462 5.53045 25.3063 4.7269C23.3663 3.92335 21.2871 3.50977 19.1873 3.50977Z"
                fill="black"
              />
            </svg>
          </div>
        </div>
      </li>
      {confirmBox2 && (
      <DeleteConfirmation
        setConfirmBox2={setConfirmBox2}
        eventID={eventID}
        api={
          "https://9jadelivery.com/femaletechMobile/public/api/deletePodcast"
        }
        setEditMode={setEditMode}
        isActive={isActive}
        menu2Active={menu2Active}
        setMenu2Active={setMenu2Active}
        menuLength={menuLength}
      />)}
    </>
  );
};

export default AdminPodcast;
