import React, { useState, useEffect } from "react";
import axios from "axios";
import FormSuccessfulMessage from "./FormSuccessfulMessage";

const AdminPodcastForm = ({
  setMenu2Active,
  itemToEdit,
  editMode,
  filePreview,
  setFilePreview,
  coverImagePreview,
  setcoverImagePreview,
  isLoading,
  setIsLoading,
}) => {
  const [podcastFormData, setPodcastFormData] = useState({
    audio: null,
    payment_type: null,
    title: "",
    description: "",
    genre: "",
    minutes: "",
    cover_image: null,
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (editMode) {
      setPodcastFormData((prevFormData) => ({
        ...prevFormData,
        audio: itemToEdit.audio,
        id: itemToEdit.id,
        payment_type: itemToEdit.payment_type,
        title: itemToEdit.title,
        description: itemToEdit.description,
        genre: itemToEdit.genre,
        minutes: itemToEdit.minutes,
        cover_image: itemToEdit.cover_image,
      }));
    }
  }, [editMode, itemToEdit, itemToEdit.typeOfContent]);

  const handleInputChange = ({ target }) => {
    const { name, value } = target;

    setPodcastFormData({
      ...podcastFormData,
      [name]: value,
    });
  };

  const handleFileUpload = (e) => {
    const fileData = e.target.files[0];

    setPodcastFormData({
      ...podcastFormData,
      audio: fileData,
    });
  };

  const handleCoverImage = (e) => {
    const fileData = e.target.files[0];

    setPodcastFormData({
      ...podcastFormData,
      cover_image: fileData,
    });
  };

  const postPodcast = async (resourceData) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://9jadelivery.com/femaletechMobile/public/api/createPodcast",
        resourceData
      );

      if (response.data && response.data.code === 200) {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error posting program:", error.message);
      throw error;
    }
  };

  const editPodcast = async (resourceData) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://9jadelivery.com/femaletechMobile/public/api/createPodcast",
        resourceData
      );
      if (response.data && response.data.code === 200) {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error posting program:", error.message);
      throw error;
    }
  };

  const validateForm = (podcastFormData) => {
    const isAnyFieldEmpty = Object.entries(podcastFormData).some(
      ([key, value]) => {
        if (
          key === "audio" ||
          key === "payment_type" ||
          key === "cover_image"
        ) {
          return value === null;
        }
        return value === "";
      }
    );

    if (isAnyFieldEmpty) {
      setInputError(true);
      return;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    validateForm(podcastFormData);
    addPodcast(podcastFormData);
    console.log(podcastFormData);

    setInputError(false);
  };

  const addPodcast = async (payload) => {
    const formData = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
      formData.append(key, value);
    });

    try {
      editMode ? await editPodcast(formData) : await postPodcast(formData);
      setFormSubmitted(true);
    } catch (err) {
      setErrorMessage(err.message);
      setIsLoading(false);
    }
  };

  const handleFormHomeBtn = () => {
    setMenu2Active(1);
  };

  return (
    <div className="resource-form event-form programme-form">
   {errorMessage ? (
        <ErrorMessage
          message={`Error submitting form : ${errorMessage}`}
        />
      ) : (<form onSubmit={isLoading ? (e) => e.preventDefault() : handleSubmit}>
        {!formSubmitted ? (
          <div>
            <label>
              Title:
              <input
                type="text"
                name="title"
                value={podcastFormData.title}
                onChange={handleInputChange}
                placeholder="Podcast title"
              />
            </label>
            <br />
            <label>
              Description:
              <textarea
                id="description"
                name="description"
                rows="4"
                cols="50"
                value={podcastFormData.description}
                onChange={handleInputChange}
                placeholder="Enter a description"
              ></textarea>
            </label>
            <br />
            {filePreview ? (
              <div>
                <audio controls>
                  <source src={itemToEdit.audio} type="audio/mp3" />
                  Your browser does not support the audio element. Upload mp3
                </audio>
                <p
                  className="remove-form-flyer"
                  onClick={() => setFilePreview(false)}
                >
                  Remove File
                </p>
              </div>
            ) : (
              <label>
                Upload podcast:
                <input
                  type="file"
                  id="audio"
                  accept="audio/*"
                  onChange={(e) => handleFileUpload(e)}
                />
              </label>
            )}

            <br />
            <label>
              Payment type:
              <select
                name="payment_type"
                value={podcastFormData.payment_type}
                onChange={handleInputChange}
              >
                <option value="">Select</option>
                <option value="Free">Free</option>
                <option value="Paid">Paid</option>
              </select>
            </label>
            <br />
            <label>
              Genre:
              <input
                type="text"
                name="genre"
                value={podcastFormData.genre}
                onChange={handleInputChange}
                placeholder="Nill"
              />
            </label>
            <br />
            <label>
              Time (minutes):
              <input
                type="text"
                name="minutes"
                value={podcastFormData.minutes}
                onChange={handleInputChange}
                placeholder="Nill"
              />
            </label>
            <br />
            {coverImagePreview ? (
              <div>
                <img
                  className="form-flyer"
                  src={podcastFormData.cover_image}
                  alt="Resource Cover"
                />
                <p
                  className="remove-form-flyer"
                  onClick={() => setcoverImagePreview(false)}
                >
                  Remove Cover Image
                </p>
              </div>
            ) : (
              <label>
                Upload cover image:
                <input
                  type="file"
                  id="image"
                  accept="image/*"
                  onChange={(e) => handleCoverImage(e)}
                />
              </label>
            )}
            <br />
            <button type="submit" style={{ cursor: isLoading ? "not-allowed" : "pointer" }} className="continue btn2Style">
              {editMode ? "Update content" : "Upload content"}
            </button>
            {inputError && (
              <p className="input-error">⛔ Please fill in all fields</p>
            )}
          </div>
        ) : (
          <FormSuccessfulMessage
            text={
              editMode
                ? "Podcast updated successfully"
                : "Podcast uploaded successfully"
            }
            onclick={handleFormHomeBtn}
          />
        )}
      </form>)}
    </div>
  );
};

const ErrorMessage = ({ message }) => {
  return (
    <p className="error">
      <span>⛔️</span> {message}
    </p>
  );
}

export default AdminPodcastForm;
