import { Link } from "react-router-dom";
import "../styles/navbar.css";
import Button from "./Button";
import logo from "../assets/images/logo.png";
import Hamburger from "../assets/icons/hamburger.svg";
import CancelBtn from "../assets/icons/cancel.svg";
import { useState } from "react";

const Navbar = () => {
  const [menuToggle, setMenuToggle] = useState(false);

  return (
    <header>
      <div style={menuToggle ? { display: "none" } : { display: "flex" }}>
        <Logo />
        <div className="menu-desktop">
          <nav>
            <MenuList />
            <div>
              <Button text="LOG IN" className="btn1Style" />
              <Button text="SIGN UP" className="btn2Style" />
            </div>
          </nav>
        </div>
        <button className="hamburger" onClick={() => setMenuToggle(true)}>
          <img src={Hamburger} alt="Hamburger" />
        </button>
      </div>
      <MobileMenu
        style={menuToggle ? { display: "block" } : { display: "" }}
        onSetMenuToggle={setMenuToggle}
      />
    </header>
  );
};

export const Logo = () => {
  return (
    <div className="logo">
      <a href="/">
        <img src={logo} alt="logo" />
      </a>
    </div>
  );
};

const MenuList = () => {
  const menu = [
    { title: "Home", path: "/" },
    { title: "About Us", path: "/about-us" },
    { title: "Business Hub", path: "/business-hub" },
    { title: "Programmes", path: "/programmes" },
    { title: "Partnership", path: "/partnership" },
    { title: "Media", path: "/media" },
    { title: "Shop", path: "/shop" },
    { title: "Contact Us", path: "/contact" },
  ];

  return (
    <ul>
      {menu.map((item, index) => (
        <li key={index}>
          <Link to={item.path}>{item.title}</Link>
        </li>
      ))}
    </ul>
  );
};

const MobileMenu = ({ style, onSetMenuToggle }) => {
  return (
    <div style={style} className="mobile-menu">
      <div className="cancel" onClick={() => onSetMenuToggle(false)}>
        <button>
          <img src={CancelBtn} alt="Cancel" />
        </button>
      </div>

      <div>
        <nav>
          <MenuList />
          <div>
            <Button text="LOG IN" className="btn1Style" />
            <Button text="SIGN UP" className="btn2Style" />
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
